.nav {
  position: fixed;
  display: flex;
  align-items: flex-start;
  top: 3rem;
  gap: 1.5625rem;
  left: 47%;
}



/* .nav-about {
  transform: translateX(-1.56rem);
} */
/* .nav-skills {
  transform: translateX(-6.25rem);
}
.nav-projects {
  transform: translateX(-10.94rem);
} */
/* .nav-contact {
  transform: translateX(-15.62rem);
} */

a {
  width: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.page-title {
  font-size: 0.875rem;
  color: white;
  cursor: default;
}

.nav-link {
  opacity: 0.7;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link.current {
  opacity: 1;
}

@media (max-width: 1100px) {
  .nav-about,
  .nav-skills,
  .nav-projects,
  .nav-contact {
    transform: translateX(0);
  }
  .nav {
    top: calc(100vh - 8rem);
    transform: translateX(-50%);
    left: 50%;
  }
  .nav-link {
    width: 2rem;
  }
  .nav-link img {
    width: 2rem;
  }
  /* .page-title {
    display: none;
  } */
}
