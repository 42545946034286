@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

body {
  font-family: "Russo One", sans-serif;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --purple: green;
  --blue: green;
  --cyan: green;
  --transparent: #00000000;
  --radius-left: 3rem 0 0 3rem;
  --radius-right: 0 3rem 3rem 0;
}

button:hover,
.class-sub-container a:hover,
.project-item:hover,
.skill-item:hover,
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-3:not(.active-subheading):hover {
  background-image: linear-gradient(90deg, #ffffff00, #ffffff42 50%, #ffffff00);
}

.social-links {
  position: fixed;
  display: flex;
  align-items: flex-start;
  gap: 1.5625rem;
  top: 90vh;
  left: 47%;
}

.social__link__icon{
  color: white;
  font-size: 2.5rem;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1100px) {
  .social-links {
    position: absolute;
    display: flex;
    justify-content: end;
    gap: 1rem;
    top: 1%;
    right: 3%;
    /* right: -1vh; */
  }

  .social__link__icon__small{
    color: white;
    font-size: 1.5rem;
    transition: all 0.3s ease-in-out;
  }
  
}